import { fetchData, setAuthHeader } from '../utils/datasource';

const defaultUser = {
  username: 'paulo',
  name: 'PAULO PRECHT'
}

export const signIn = async (username, password) => {
  try {
    console.log('signIn()')
    setAuthHeader(username, password)
    const response = await fetchData(`/DCVALUSR/api/dc/loginuser`, 'GET');

    const loginData = await response.json()

    console.log(loginData)

    // Verifica se há um erro no login (exemplo: PASS_INVALID_TESTE)
    if (loginData.erro_access) {
      throw new Error(`Erro de autenticação: ${loginData.erro_access}`);
    }

    // Valida se codi_usuario existe
    if (!loginData.codi_usuario) {
      throw new Error('Usuário não encontrado.');
    }

    localStorage.setItem('authCredentials', btoa(`${username}:${password}`));

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch (error) {
    console.error(error)
    return {
      isOk: false,
      message: error.message
    };
  }
}

export const getUser = async () => {
  try {
    // const _isOk = false
    // console.log('getUser()')
    // return {
    //   isOk: _isOk,
    //   data: defaultUser
    // };
    console.log('getUser()');
    const credentials = getAuthCredentials();
    if (credentials) {
      setAuthHeader(credentials.username, credentials.password);
      const data = await fetchData('/check_security', 'GET');
      return {
        isOk: true,
        data
      };
    } else {
      return {
        isOk: false,
        message: 'No credentials found'
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

const getAuthCredentials = () => {
  const credentials = localStorage.getItem('authCredentials');
  if (credentials) {
    const [username, password] = atob(credentials).split(':');
    return { username, password };
  }
  return null;
};